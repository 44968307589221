* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
body {
  font-family: 'Inria Serif', serif;
  font-weight: 400;
}

.navigation {
  width: auto;
  height: auto;
  background-color: #084f7a;
}
.container {
  max-width: 940px;
  margin: 0 auto;
}
.navbar2 {
  padding: 0 10px;
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: auto;
  border-bottom: 2px solid white;
}
.nav2 {
  width: 150px;
  height: 100px;
  max-width: 1000px;
  max-height: 45px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px;
  margin: 10px 0 18px;
}
.nav2 img {
  width: 25px;
  height: 30px;
  margin: 10px;
}
.call {
  font-size: 20px;
  color: #ffffff;
  font-family: inria serif;
  font-weight: 700;
  line-height: 20px;
}
.logo{
  padding: 10px 0 20px;
}
.logo img {
  width: 150px;
  height: 100px;
  max-width: 100%;
}
.navbar-toggler {
  background-color: whitesmoke;
  /* color: rgb(184, 160, 160); */
  color: rgb(14, 14, 75);
}
.navbar-nav {
  margin-top: 50px;
  height: auto;
  padding: 0 13px 50px;
  width: 100%;
  text-align: center;
}
.navbar-nav li a {
  text-transform: uppercase;
  font-weight: 700;
  transition: color 0.25s ease, background-color 0.25s ease;
}
.nav-btn {
  display: inline-block;
  height: 40px;
  width: 220px;
  padding: 5px 15px 0;
  background-color: #ffffff;
  font-size: 20px;
  color: black;
  border: none;
  border-radius: 10px;
  /* margin: 0 15px; */
}
.nav-btn:hover {
  background-color: #4095ca;
  color: white;
  text-decoration: none;
}
.navbar-nav li a:hover {
  color: gray;
}

.nav-link {
  margin: 0 10px;
  color: #ffffff;
}
@media (max-width: 768px) {

}
/* .navigation {
  width: auto;
  height: auto;
  background-color: #084f7a;
 
} 
.container {
  max-width: 940px;
  margin: 0 auto;
}
.navbar2 {
  padding: 0 10px;
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: auto;
  border-bottom: 2px solid white;
}
.nav2 {
  width: 150px;
  height: 100px;
  max-width: 1000px;
  max-height: 45px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px;
  margin: 10px 0 18px;
}
.nav2 img {
  width: 25px;
  height: 30px;
  margin: 10px;
}
.call {
  font-size: 20px;
  color: #ffffff;
  font-family: inria serif;
  font-weight: 700;
  line-height: 20px;
}
.logo img {
  width: 150px;
  height: 100px;
  max-width: 100%;
}
.navbar-nav {
  margin-top: 50px;
  height: 100px;
  padding: 0 13px;
  width: 100%;
  text-align: center;
}

.navbar-nav ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.navbar-nav ul li {
  display: inline-block;
}
.navbar-nav ul li a {
  color: #ffffff;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 700;
  padding: 0 10px;
  line-height: 40px;
  display: block;
  transition: color 0.25s ease, background-color 0.25s ease;
}
.nav-btn {
  display: inline-block;
  height: 40px;
  width: 220px;
  padding: 5px 15px 0;
  background-color: #ffffff;
  font-size: 20px;
  color: black;
  border: none;
  border-radius: 10px;
  margin: 0 15px;
}
.nav-btn:hover {
  background-color: #4095ca;
  color: white;
  text-decoration: none;
}
.navbar-nav li a:hover {
  color: black;
  background-color: white;
}

.nav-link{
  margin: 0 10px;
  color: #ffffff;
}
@media (max-width: 768px) {
  .navbar-nav{
    margin: 20px auto;
    width: 300px;
  }
 .navbar-nav ul li {
  display: block;
    text-align: center;
    width: 250px;

}
.navbar-toggler {
    margin: 0 0 0 350px;
}
.navbar-nav li a:hover {
  color: black;
  background-color: #1099ec;
}
}
@media (max-width: 478px) {
  .navbar-toggler {
    margin: 0 10px;
}
.container{
  margin: 0 10px ;
}
.call{
  font-size: 14px;
}
.nav2 img {
  width: 20px;
  height: 25px;
  margin: 10px;
}


}  */
