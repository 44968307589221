.gallery-section{
background-color: #ffffff;
margin-top: -10px;
}
.gallery-container{
    margin: 50px auto 0;
    height: 600px;
    max-width: 940px;
}
.c-img{
   width: auto;
    height: 550px;
    margin: 0  auto;
}
.item-gallary{
    height: 800px;
}