.middle {
  padding: 20px;
  color: #ffffff;
  width: auto;
  height: auto;
  background-color: #3898ec;
}
.aboutsection-content {
  margin-top: 70px;
}
.aboutsection-container-top,
.aboutsection-container-bottom {
  padding: 10px;
  margin: 0 auto;
  max-width: 940px;
}
.main-heading {
  text-align: center;
  font-size: 45px;
  font-weight: 700;
}
.aboutsection-section h2 {
  font-weight: 700px;
  text-align: center;
}
.aboutsection-text {
  padding: 10px;
  margin: 0 auto;
  max-width: 940px;
}
.aboutsection-text p,
.history p,
.award p {
  font-size: 22px;
  line-height: 30px;
  font-weight: 300;
}
.side-section {
  background-color: #ffffff;
}
.side {
  display: flex;
  flex-direction: row;
  gap: 50px;
  margin: 40px auto;
  color: black;
  max-width: 940px;
}
.side-one,
.side-two {
  width: 400px;
}
.side p {
  font-size: 18px;
  font-weight: 300;
  text-align: justify;
}
.img {
  width: 100px;
  height: 100px;
  background-color: #ffffff;
  border-radius: 50%;
}
.team-list {
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.team-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.middle p,
.middle h2 {
  color: #ffffff;
}
.award {
  max-width: 940px;
  margin: 10px auto;
  text-align: center;
}
.history {
  text-align: center;
}
.why-c{
  display: flex;
  flex-direction: row;
  gap: 30px;
  height: 350px;
  padding: 50px 0;
}
.why-c-items{
  width: 250px;
  height: 200px;
  /* background-color: rgb(238, 218, 218); */
  text-align: center;
}
.why-c-items img{
width: 150px;
height: 100px;
border-radius: 50%;
}
.why-c-heading{
  
  padding: 30px;
}
@media (max-width: 900px){
.aboutsection-container-top,
.aboutsection-container-bottom {
  padding: 10px;
  margin: 0 auto;
  max-width: 790px;
}
.aboutsection-text p, .history p, .award p {
    font-size: 20px;
    line-height: 25px;
    font-weight: 300;
    text-align: center;
}
}
@media (max-width: 768px){
.aboutsection-container-top,
.aboutsection-container-bottom {
  padding: 10px;
  margin: 0 auto;
  max-width: 728px;
}
.aboutsection-text p, .history p, .award p {
    font-size: 20px;
    line-height: 25px;
    font-weight: 300;
    text-align: center;
}
}
@media (max-width: 478px) {
  .aboutsection-container-top {
    max-width: 320px;
    width: 300px;
    /* background-color: red; */
    margin: 0 auto;
  }
  .main-heading {
    width: 250px;
    font-size: 35px;
  }
  .aboutsection-text p {
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    width: 250px;
  }
  .side {
    display: block;
    color: black;
    max-width: 320px;
  }
  .side p {
    width: 280px;
    text-align: center;
    padding: 0 10px;
  }

  .side-one,
  .side-two {
    width: 280px;
    padding: 10px;
  }
  .aboutsection-section h2 {
    width: 250px;
    text-align: center;
    margin: 0 auto;
  }
  .aboutsection-text p,
  .history p,
  .award p {
    font-size: 20px;
    line-height: 25px;
    font-weight: 100;
  }
  .team-list {
    display: block;
  }
  .team-item {
    padding: 10px;
  }
  .team {
    padding: 10px;
    margin: 0 auto;
    max-width: 320px;
    width: 300px;
  }
  .award{
    width: 300px;
    max-width: 320px;
  }
}
