.blog-section {
  width: auto;
  height: 800px;
  background-color: #1099ec;
  margin-top: -10px;
}
.blog-content {
  max-width: 940px;
  width: auto;
  height: auto;
  margin: 0 auto;
}
.blog-container {
  margin: 40px auto 10px;
  max-width: 940px;
  width: auto;
  height: auto;
  display: flex;
  gap: 50px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
}
.blog-item {
  width: 260px;
  height: 370px;
  padding: 0 10px;
  border-radius: 15px;
  background-color: #ffffff;
}
.blog-item img {
  width: 240px;
  height: 200px;
}
.blog-item h4 {
  width: 220px;
  height: 70px;
  margin: 20px auto 0;
  text-align: center;
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
}
.blog-footerpost {
  display: flex;
  align-items: stretch;
  justify-content: left;
  gap: 20px;
  margin: 10px 0 0;
  padding: 10px;
}
.author {
  display: flex;
  align-items: center;
  justify-content: left;
  gap: 10px;
}
.author img {
  width: 30px;
  height: 30px;
}
.author p {
  font-size: 12px;
  line-height: 15px;
}
.blog-btn {
  width: 240px;
  height: 70px;
  margin: 40px auto 0;
  padding: 15px 20px 10px;
  font-size: 40px;
  line-height: 40px;
  border-radius: 10px;
  background-color: #ffffff;
}
@media (max-width: 900px) {
  .blog-content{
    max-width: 700px;
  }
  .blog-container{
    max-width: 728px;
    width: 700px;
  }
  .blog-item{
    width: 200px;
  }
  .blog-item img{
    width: 180px;
  }
  .blog-item h4 {
  width: 180px;
  font-size: 16px;
}
.author img {
  width: 20px;
  height: 20px;
}
}

@media (max-width: 768px) {
  .blog-content{
    max-width: 700px;
  }
  .blog-container{
    max-width: 728px;
    width: 700px;
  }
  .blog-item{
    width: 200px;
  }
  .blog-item img{
    width: 180px;
  }
  .blog-item h4 {
  width: 180px;
  font-size: 16px;
}
.author img {
  width: 20px;
  height: 20px;
}
}
@media (max-width: 478px) {
  .blog-section{
    height: 1550px;
  }
  .blog-content{
    max-width: 320px;
    width: 300px;
  }
  .blog-container{
    max-width: 320px;
    width: 300px;
    display: block;
    margin: 0 auto;
  }
  .blog-item {
    margin: 20px auto;
  width: 260px;
  height: 370px;
  padding: 0 10px;
  border-radius: 15px;
  background-color: #ffffff;
}
.blog-item img {
  width: 240px;
  height: 200px;
}
.blog-item h4 {
  width: 220px;
  height: 70px;
  margin: 20px auto 0;
  text-align: center;
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
}
.blog-footerpost {
  display: flex;
  align-items: stretch;
  justify-content: left;
  gap: 20px;
  margin: 10px 0 0;
  padding: 10px;
}
.author {
  display: flex;
  align-items: center;
  justify-content: left;
  gap: 10px;
}
.author img {
  width: 30px;
  height: 30px;
}
.author p {
  font-size: 12px;
  line-height: 15px;
}
}

