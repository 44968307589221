.footer-section{
    background-color: #084f7a;
    width: auto;
    height: auto;

}
.footer-container{
    max-width: 940px;
    margin: 0  auto;
    width: auto;
    height: auto;
    display: flex;
    align-items: flex-start;
    justify-content: center;

}
.footer-items, .footer-items-top{
    width: 300px;
    height: auto;
    margin: 40px 0 0;
    padding: 10px;

}
.footer-items a{
    text-decoration: none;
    color: #ffffff;
}
.footer-items a:hover{
    color: gray;
}
.footer-logo{
    width: 200px;
    height: 200px;
}
.footer-socials {
       margin: 20px 0 0;
}
.footer-socials img{
    width: 50px;
    height: 50px;
    margin-left: 10px;
}
.footer-items h3{
    font-size: 30px;
    line-height: 40px;
    text-align: center;
    color: white;
    font-weight: 700;
}
.footer-items h5{
    font-size: 20px;
    line-height: 40px;
    text-align: center;
    font-weight: 700;
    color: white;
    text-align: center;
}
.footer-address, .footer-mail{
    width: 250px;
    padding: 10px 11px;
    display: flex;
    align-items: center;
    justify-content: left;
    gap: 10px;
}
.footer-address img{
    width: 30px;
    height: 35px;
}
.footer-address span, .footer-mail span{
font-size: 16px;
line-height: 20px;
color: #ffffff;
}
.footer-mail img{
    width: 30px;
    height: 30px;
}
.copy-right{
    color: #ffffff;
    font-size: 16px;
    text-align: center;
    background-color: #074a72;
    margin-top: 10px;
    height: 50px;
    padding: 10px;
}
@media (max-width: 900px){
    .footer-container{
        max-width: 728px;
        width: 700px;

    }
    .footer-items, .footer-items-top{
        width: 250px;
    }
    .footer-socials img{
    width: 40px;
}
.footer-address img{
    width: 25px;
    height: 30px;
}
.footer-address span, .footer-mail span{
    font-size: 14px;
}
.footer-mail img{
    width: 25px;
    height: 25px;
}
.footer-items h5 {
    font-size: 18px;
}
}
@media (max-width: 768px){
    .footer-container{
        max-width: 728px;
        width: 700px;

    }
    .footer-items, .footer-items-top{
        width: 250px;
    }
    .footer-socials img{
    width: 40px;
}
.footer-address img{
    width: 25px;
    height: 30px;
}
.footer-address span, .footer-mail span{
    font-size: 14px;
}
.footer-mail img{
    width: 25px;
    height: 25px;
}
.footer-items h5 {
    font-size: 18px;
}
}
@media (max-width: 478px){
    .footer-section{
        margin-top: -40px;
    }
    .footer-container{
        max-width: 320px;
        width: 300px;
        display: block;

    }
    .footer-items, .footer-items-top{
        width: 250px;
        margin: 40px auto 0;
        padding: 10px;
    
       
    }
    .footer-items {
        margin-bottom: 10px;
         border-top: 2px solid white;
    }
        .footer-socials img{
    width: 50px;
}
}