.testimony-section {
  margin-top: -10px;
  background-color: #dbe3dd;
  height: 800px;
  width: auto;
}
.testimony-container {
  max-width: 940px;
  margin: 40px auto 0;
  width: auto;
  height: auto;
}
.slide {
  height: 500px;
  width: auto;
  background-color: white;
}
.item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 32px;
  margin: 10px auto 0;
  width: 700px;
  height: 300px;
}
.item-img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
}
.item p {
  width: 400px;
  height: 150px;
  font-size: 25px;
  font-weight: 400;
  line-height: 25px;
  text-align: center;
}
.testi-footer {
  width: 700px;
  margin: 0 auto;
  display: flex;
  align-items: stretch;
  justify-content: space-around;
  gap: 10px;
}
.testi-footer span {
  font-size: 22px;
  width: 250px;
  line-height: 40px;
  text-align: center;
}
.star {
  width: 180px;
  height: 50px;
}
.star img {
  width: 30px;
  height: 30px;
}
.carousel-control-next-icon,
.carousel-control-prev-icon {
  background-color: gray;
}
@media (max-width: 900px) {
  .testimony-section {
    height: 750px;
  }
  .testimony-container {
    max-width: 728px;
  }
  .slide {
    height: 450px;
    width: 700px;
    margin: 0 auto;
  }
  .item {
    width: 400px;
  }
  .item p {
    font-size: 18px;
  }
  .item-img {
    width: 100px;
    height: 100px;
  }
  .testi-footer span {
    font-size: 16px;
    line-height: 25px;
  }
}

@media (max-width: 768px) {
  .testimony-section {
    height: 700px;
  }
  .testimony-container {
    max-width: 728px;
  }
  .slide {
    height: 450px;
    width: 700px;
    margin: 0 auto;
  }
  .item {
    width: 400px;
  }
  .item p {
    font-size: 18px;
  }
  .item-img {
    width: 100px;
    height: 100px;
  }
  .testi-footer span {
    font-size: 16px;
    line-height: 25px;
  }
}
@media (max-width: 478px) {
    .testimony-section{
        height: 550px;
    }
  .testimony-container {
    max-width: 320px;
    width: 300px;
  }
  .item {
    width: 280px;
  }
  .slide {
    width: 300px;
    height: 350px;
  }
  .item p {
    width: 150px;
    font-size: 12px;
    line-height: 20px;
  }
  .item-img {
    width: 70px;
    height: 70px;
  }
  .testi-footer{
width: 280px;
  }
  .testi-footer span {
    font-size: 12px;
    line-height: 20px;
  }
  .star img{
    width: 20px;
    height: 20px;
  }
}
