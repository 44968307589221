.ambulance-container {
  max-width: 940px;
  margin: 100px auto;
}
.header {
  text-align: center;
  font-size: 55px;
  font-weight: 800;
  letter-spacing: 1px;
  margin-bottom: 15px;
}
.header-paragraph {
  text-align: center;
  font-size: 20px;
}
.ambu-btn,
.ambup-btn {
  padding: 15px 15px 9px;
  width: 200px;
  height: 50px;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  background-color: #3898ec;
  border-radius: 10px;
  color: white;
}
.ambu-footer button,
.ambup-footer button {
  border: none;
}
.ambu-footer button:hover,
.ambup-footer button:hover {
  background-color: #0c477a;
}
.ambu-footer,
.ambup-footer {
  width: 300px;
  height: 70px;

  padding: 10px 50px;
}
.ambu-footer {
  margin: 50px auto;
}
.ambup-footer {
  margin: 20px AUTO;
}

.ambulancepage-section {
  background-color: #dbe3dd;
}
.section-container {
  max-width: 940px;
  margin: 0 auto;
}
.form-section {
  width: 800px;
  margin: 0 auto;
  padding: 80px;
}
.ambulance-form {
  margin: 20px auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  align-items: stretch;
  justify-content: stretch;
  padding: 10px;
  width: 600px;
}
.ambu-form {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  width: 260px;
}
.ambulance-form input, .select {
  width: 250px;
  height: 60px;
  border: none;
  padding: 5px;
  font-size: 14px;
  font-weight: 400;
  border-radius: 10px;
}
.ambulance-form input::placeholder {
  padding: 0 10px;
  font-size: 14px;
}
.ambulance-form input:focus {
  border: 1px solid;
  border-color: rgb(190, 171, 171);
  box-shadow: none;
  outline: 0 none;
}
.select:focus{
 border: 1px solid;
 border-color: rgb(190, 171, 171);
  box-shadow: none;
  outline: 0 none;
}
.form-section h4{
  text-align: center;
}
.ambulance-img{
  width: 600px;
  margin: 0 auto 20px;
}
@media (max-width: 900px) {
   .ambulance-container {
    max-width: 728px;
    width: 700px;
    height: 600px;
    margin: 50px auto 0;
  }
   .header-paragraph{
    font-size: 20px;
    width: 500px;
    margin: 0 auto;
  }
   .ambulance-img img{
  width: 500px;
 }
  .header {
    font-size: 40px;
  }
}
@media (max-width: 768px) {
  .ambulance-container {
    max-width: 728px;
    width: 700px;
    height: 600px;
    margin: 50px auto 0;
  }
  .ambulance-img img{
  width: 500px;
 }
   .header-paragraph{
    font-size: 20px;
    width: 500px;
    margin: 0 auto;
  }
  .header {
    font-size: 40px;
  }
}
@media (max-width: 478px) {
  .ambulance-container {
    max-width: 320px;
    width: 300px;
    height: 500px;
  }
 .ambulance-img img{
  width: 300px;
 }
  .header-paragraph{
    font-size: 16px;
    width: 250px;
    margin: 0 auto;
  }
  .header {
    font-size: 35px;
  }
  .ambulancepage-section {
    height: 900px;
  }
  .section-container {
    max-width: 320px;
  }
  .form-section h4 {
    padding: 40px 0 0;
    font-size: 20px;
    text-align: center;
  }
  .ambulance-form {
    width: 280px;
    display: block;
    margin: 0 auto;
    padding: 0;
    height: 680px;
  }
  .form-section {
    width: 300px;
    margin: 0 auto;
    padding: 0;
  }
  .ambu-form {
    margin: 20px 0;
  }
  .ambu-form label {
    margin: 0 10px 10px;
  }
  .ambu-form input {
    width: 250px;
  }
  .form-section form {
    width: 280px;
    display: block;
    margin: 0 auto;
  }
}
