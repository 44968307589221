.appointment-section {
  height: 900px;
  width: auto;
  background-color: #dbe3dd;
}
.appointmentpage-section {
  background-color: #dbe3dd;
  /* height: 800px; */
 padding: 0 0 50px;

}
.appointment-container {
  max-width: 940px;
  width: auto;
  height: auto;
  margin: -10px auto 0;
}
.call-div{
  max-width: 940px;
  margin: 10px auto;
  width: auto;
}
.call-div h2{
  text-align: center;
}
.call-div h4{
  text-align: center;
  font-size: 20px;
}
.appoint {
  width: 450px;
}
.appointment-content {
  margin: 100px 0 100px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  height: auto;
  gap: 10px;
}
.appointment-text {
  width: 350px;
  height: auto;
  padding: 10px;
}
.appointment-text p {
  width: 320px;
  height: 150px;
  font-size: 20px;
  line-height: 25px;
  font-weight: 700;
}
.appoint-form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  align-items: stretch;
  justify-content: stretch;
  padding: 10px;
}
.appoint-form:last-child{
  margin: 20px auto;
}
.input-form {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
}
.appoint-form input, .appoint-form select  {
  width: 250px;
  height: 60px;
  border: none;
  padding: 5px;
  font-size: 14px;
  font-weight: 400;
  border-radius: 10px;
}
.appoint-form input::placeholder {
  padding: 0 10px;
  font-size: 14px;
}
.appoint-form input:focus {
  border: 2px solid;
  border-color: rgb(190, 171, 171);
  box-shadow: none;
  outline: 0 none;
}
.form-btn {
  padding: 15px 15px 9px;
  width: 200px;
  height: 50px;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  background-color: #3898ec;
  border-radius: 10px;
  color: white;
}
.form-footer {
  /* display: flex;
  gap: 7px;
  justify-content: center;
  align-items: center; */
  margin: 20px auto;
  width: 250px;
}
.form-footer button {
  border: none;
}
.form-footer button:hover {
  background-color: white;
  color: black;
}
.form-footer p {
  font-size: 20px;
  line-height: 20px;
}
.form-foot img {
  width: 25px;
  height: 25px;
}
.form-foot {
  margin: 20px auto;
  width: 250px;
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: center;
}
.form-foot span {
  font-size: 20px;
}
.form-foot a{
  text-decoration: none;
  color: #222;
}
.form-foot a:hover{
  color: rgb(216, 154, 154);
}
.notification {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
@media (max-width: 900px) {
 
  .appointment-container {
    max-width: 728px;
    width: 780px;
  }
  .appointment-content {
    width: 650px;
  }
  .appointment-text {
    width: 350px;
  }
  .appointment-text p {
    font-size: 16px;
    text-align: left;
    width: 250px;
    height: 200px;
  }
  .input-form label {
    margin: 0 10px 10px;
    font-size: 12px;
  }
  .appoint-form {
    width: 350px;
  }
  .appoint-form input {
    width: 200px;
  }
  .form-btn {
    width: 150px;
    font-size: 12px;
  }
  .form-btn p {
    font-size: 20px;
  }
  .form-foot img {
    width: 20px;
    height: 20px;
  }
  .form-foot span {
    font-size: 16px;
  }
  .form-foot {
    width: 150px;
  }
  .appointmentpage-section {
    padding: 20px 0;
    height: 1200px;
  }
}
@media (max-width: 768px) {
  .appointmentpage-section {
    padding: 20px 0;
    height: 680px;
  }
  .appointment-container {
    max-width: 728px;
    width: 700px;
  }
  .appointment-content {
    width: 650px;
  }
  .appointment-text {
    width: 350px;
  }
  .appointment-text p {
    font-size: 16px;
    text-align: left;
    width: 250px;
    height: 200px;
  }
  .input-form label {
    margin: 0 10px 10px;
    font-size: 12px;
  }
  .appoint-form {
    width: 350px;
  }
  .appoint-form input {
    width: 180px;
    height: 50px;
  }
  .form-btn {
    width: 150px;
    font-size: 12px;
  }
  .form-btn p {
    font-size: 20px;
  }
  .form-foot img {
    width: 20px;
    height: 20px;
  }
  .form-foot span {
    font-size: 16px;
  }
  .form-foot {
    width: 150px;
  }
}
@media (max-width: 478px) {
  .appointment-section {
    height: 1400px;
  }
  .appointment-container {
    max-width: 320px;
    width: 300px;
  }
  .appointment-content {
    width: 300px;
    display: block;
    margin: 40px auto 0;
  }

  .appointment-text {
    width: 250px;
    height: 150px;
  }
  .appointment-text p {
    font-size: 14px;
    text-align: center;
    width: 230px;
    height: 130px;
    line-height: 25px;
  }
  .input-form {
    margin: 20px 0;
  }
  .input-form label {
    margin: 0 10px 10px;
  }
  .appoint-form {
    width: 280px;
    display: block;
    margin: 0 auto;
  }
  .appoint-form input {
    width: 250px;
  }
  .form-footer {
    flex-direction: column;
    padding: 10px;
    gap: 7px;
    margin: 20px auto 0;
  }
  .form-btn {
    width: 200px;
    font-size: 16px;
  }
  .form-btn p {
    font-size: 20px;
  }
  .form-foot img {
    width: 25px;
    height: 25px;
  }
  .form-foot span {
    font-size: 16px;
  }
  .form-foot {
    width: 250px;
  }
  .appoint {
    font-size: 40px;
    line-height: 45px;
    width: 250px;
  }
}
