.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  backdrop-filter: blur(5px);
}

.loader-dev {
  width: 50px;
}

.lds-heart {
  color: red;
}
.lds-heart,
.lds-heart div,
.lds-heart div:after,
.lds-heart div:before {
  box-sizing: border-box;
}

.lds-heart {
  display: inline-block;
  position: relative;
  width: 120px; 
  height: 120px; 
  transform: rotate(45deg);
  transform-origin: 60px 60px; 
}

.lds-heart div {
  top: 42px; 
  left: 42px;
  position: absolute;
  width: 48px; 
  height: 48px; 
  background: currentColor;
  animation: lds-heart 0.8s infinite cubic-bezier(0.215, 0.61, 0.355, 1); /* Faster beat rate */
}

.lds-heart div:after,
.lds-heart div:before {
  content: ' ';
  position: absolute;
  display: block;
  width: 48px; 
  height: 48px; 
  background: currentColor;
}

.lds-heart div:before {
  left: -36px; 
  border-radius: 50% 0 0 50%;
}

.lds-heart div:after {
  top: -36px; 
  border-radius: 50% 50% 0 0;
}

@keyframes lds-heart {
  0% {
    transform: scale(0.95);
  }
  5% {
    transform: scale(1.1);
  }
  39% {
    transform: scale(0.85);
  }
  45% {
    transform: scale(1);
  }
  60% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(0.9);
  }
}
