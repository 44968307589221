.service-section {
  width: auto;
  background-color: #dbe3dd;
  margin-top: -10px;
  height: 1250px;
}
.service-container {
  max-width: 940px;
  margin: 0 auto;
  width: auto;
}
.section-text {
  width: auto;
  margin: 10px 0 20px;
  padding-top: 20px;
}
.section-heading {
  margin: 20px auto 10px;
  width: 400px;
  font-size: 38px;
  line-height: 44px;
  font-weight: 700;
  text-align: center;
}
.section-subheading {
  width: 700px;
  margin: 20px auto 10px;
  font-size: 28px;
  line-height: 30px;
  font-weight: 700;
  text-align: center;
}
.section-paragraph {
  padding: 10px 0;
  margin: 0 auto 10px;
  width: 350px;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
}
.service-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 50px;
  padding: 20px;
}
.service-item {
  perspective: 1000px;
}
.flip-card-inner {
  position: relative;
  width: 265px;
  height: 370px;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}
.flipped .flip-card-inner {
  transform: rotateY(180deg);
}
.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 10px;
  box-sizing: border-box;
}
.flip-card-back {
  transform: rotateY(180deg);
}
.service-item h1 {
  font-size: 25px;
  line-height: 35px;
  font-weight: 700;
  text-align: center;
}
.service-item p {
  font-size: 16px;
  line-height: 18px;
  text-align: center;
}
.service-item img {
  width: 90px;
  height: 90px;
  margin-bottom: 10px;
}
.service-btn {
  padding: 10px 15px 0;

  background-color: #3898ec;
  font-size: 20px;
  color: #ffffff;
  line-height: 40px;
  font-weight: 400;
  text-align: center;
  border-radius: 8px;
  text-decoration: none;
  border: none;
}
.s-btn {
  margin: 40px auto 10px;
  width: 250px;
}
.service-btn:hover {
  background-color: #ffffff;
  color: black;
}

@media (max-width: 900px) {
  .service-section {
    height: 1600px;
  }
  .service-container {
    max-width: 750px;
    margin: 0 auto;
    width: 650px;
  }
  .section-heading {
    width: 250px;
    font-size: 30px;
  }
  .section-subheading {
    width: 700px;
    font-size: 23px;
  }
  .section-paragraph {
    font-size: 17px;
  }
  .service-grid {
    grid-template-columns: 1fr 1fr;
    gap: 30px;
    align-items: center;
    justify-content: center;
    width: 700px;
    margin: 0 auto;
  }
}

@media (max-width: 769px) {
  .service-section {
    height: 1600px;
  }
  .service-container {
    max-width: 728px;
    margin: 0 auto;
    width: 650px;
  }
  .section-heading {
    width: 250px;
    font-size: 30px;
  }
  .section-subheading {
    width: 700px;
    font-size: 23px;
  }
  .section-paragraph {
    font-size: 17px;
  }
  .service-grid {
    grid-template-columns: 1fr 1fr;
    gap: 30px;
    align-items: center;
    justify-content: center;
    width: 700px;
    margin: 0 auto;
  }
}

@media (max-width: 478px) {
  .service-section {
    height: 2800px;
  }
  .section-heading {
    font-size: 25px;
    line-height: 44px;
    text-align: center;
    margin: 20px auto 0;
  }
  .section-subheading {
    width: 350px;
    font-size: 16px;
    line-height: 30px;
    text-align: center;
    margin: 0 auto;
  }
  .section-paragraph {
    width: 300px;
    height: auto;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    margin: 0 auto;
  }
  .service-container {
    width: auto;
    margin: 0 auto;
  }
  .service-grid {
    width: 300px;
    grid-template-columns: 1fr;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin: 0 auto;
    padding: 20px;
  }
      .service-btn {
        font-size: 16px;
        margin: 0 45px ;
    }

}
