/* .reveal {
  transform: translateY(150px);
  opacity: 0;
  transition: 2s all ease;
}

.reveal.active {
  transform: translateY(0);
  opacity: 1;
  filter: blur(0); 
}

@keyframes slide-in {
  0% {
    transform: translateY(150px);
    opacity: 0;
    filter: blur(10px);
  }
  100% {
    transform: translateY(0);
    opacity: 1;
    filter: blur(0);
  }
}

.reveal.active {
  animation: slide-in 2s ease;
} */

.reveal {
  transform: translateY(150px);
  opacity: 0;
  transition: 2s all ease, filter 1s ease;
  /* filter: blur(10px);  */
}

.reveal.active {
  transform: translateY(0);
  opacity: 1;
  filter: blur(0); /* Remove blur */
}

@keyframes slide-in {
  0% {
    transform: translateY(150px);
    opacity: 0;
    /* filter: blur(10px); */
  }
  100% {
    transform: translateY(0);
    opacity: 1;
    filter: blur(0);
  }
}

.reveal.active {
  animation: slide-in 2s ease forwards;
}
