.contact-section {
  background: rgb(143, 141, 179);
  background: linear-gradient(
    -10deg,
    rgba(143, 141, 179, 1) 0%,
    rgba(225, 225, 238, 1) 32%,
    rgba(43, 157, 180, 1) 100%
  );
}
.top-section {
  margin-top: -20px;
  width: auto;
  height: 200px;
  background-color: #ffffff;
  background-size: cover;
  padding: 50px;
}
.top-section h1 {
  text-align: center;
  font-size: 50px;
  font-weight: 700;
  color: rgb(107, 72, 25);
}
.contact-container {
  max-width: 940px;
  margin: 0 auto;
  width: auto;
  height: auto;
}
.middle-container {
  max-width: 940px;
  margin: 0 auto;
  padding: 70px 0 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  align-content: center;
}
.middle-section {
  width: auto;
  height: 200px;
}
.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 700px;
}
.form-container {
  max-width: 940px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}
.form-input {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
  width: 650px;
}
.form-input input {
  width: 300px;
  margin: 10px;
  height: 50px;
  border: none;
}
.form-input button {
  color: white;
  font-size: 16px;
  width: 300px;
  height: 50px;
  border: none;
  border-radius: 10px;
  background-color: #3898ec;
}
  .contact-item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 300px;
    margin: 30px 20px;
  }
  .contact-item a{
    text-decoration: none;
    
  }
.contact-item span {
  font-size: 20px;
  font-weight: 500;
  width: 250px;
}

.contact-item img {
  margin: 0 10px;
}
.link {
  color: black;
}
.link:hover {
  text-decoration: none;
  color: antiquewhite;
}
.link.active {
  text-decoration: none;
}
.form-input button:hover{
  background-color: white;
  color: black;

}
  .form-input input::placeholder{
    color: black;
    padding: 0 10px;
    font-weight: 700;
  }
  .form-input input:focus{
    border: 2px solid;
    border-color: rgb(197, 168, 168);
    box-shadow: none;
    outline: 0 none;
  }
    #success{
    margin: 20px 0 0;
  }
  #success p{
text-align: center;
font-size: 18px;
font-weight: 500;
color: rgb(78, 151, 214);
  }

   @media (max-width: 900px) {
 .contact-container {
    max-width: 790px;
  }
  /* .middle-section{
    height: 300px;
  } */
  .middle-container {
    max-width: 790px;
    width: 770px;
      padding: 70px 0 10px;
  }
    .contact-item {
    width: 250px;
    margin: 10px 0;
  }
.contact-item span {
  font-size: 18px;
  width: 220px;
}

  }
  @media (max-width: 768px) {
 .contact-container {
    max-width: 728px;
  }
  /* .middle-section{
    height: 300px;
  } */
  .middle-container {
    max-width: 728px;
    width: 700px;
      padding: 70px 0 10px;
  }
    .contact-item {
    width: 220px;
    margin: 10px 0;
  }
.contact-item span {
  font-size: 16px;
  width: 200px;
}

  }

@media (max-width: 478px) {
  .contact-container {
    max-width: 320px;
  }
  .top-section {
    width: auto;
    height: 300px;
    background-color: #ffffff;
    padding: 50px;
  }
  .top-section h1 {
    font-size: 50px;
    width: 280px;
    line-height: 50px;

  }
  .middle-section {
    height: 350px;
  }
  .middle-container {
    max-width: 320px;
    margin: 0 auto;
    display: block;
  }
  .contact-item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 300px;
    margin: 30px 20px;
  }
  .contact-item span {
    width: 250px;
  }
  .form {
    margin: 0 auto;
    display: block;
    width: 300px;
  }
  .form h1 {
    text-align: center;
    font-size: 30px;
  }
  .form-container {
    max-width: 320px;
    margin: 0 auto;
  }
  .form-input {
    display: block;
    margin: 20px auto;
    width: 280px;
  }
  .form-input input {
    width: 250px;
    margin: 10px 15px;
  }

  .form-input button {
    margin: 10px auto;
    width: 250px;
    height: 50px;
    border: none;
    border-radius: 10px;
    background-color: #3898ec;
  }
  #success{
    margin: 20px 0;
  }
  #success p{
text-align: center;
font-size: 18px;
font-weight: 500;
color: rgb(78, 151, 214);
  }
}

