.servicepage-section {
  width: auto;
  height: 1300px;
  background-color: #dbe3dd;
  padding: 50px 10px;
}

.service-page {
  padding: 20px;
  gap: 30px;
  max-width: 940px;
  margin: 0 auto 20px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  position: relative;
}

.servicepage-item {
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: whitesmoke;
  transition: transform 0.3s, filter 0.3s; 
}
.servicepage-item h1 {
  text-align: center;
}
.servicepage-item:hover {
  transform: scale(1.25);
  z-index: 1; 
}

.servicepage-item:hover ~ .servicepage-item:not(:hover) {
  filter: blur(5px); 
}

.servicepage-item:nth-child(7),
.servicepage-item:nth-child(8) {
  grid-column: span 3;
}

.servicepage-item:nth-child(7) {
  grid-column-start: 1;
  grid-column-end: 2;
}

.servicepage-item:nth-child(8) {
  grid-column-start: 3;
  grid-column-end: 4;
}
@media (max-width: 478px) {
  .service-page {
    display: block;
    max-width: 300px;
    
  }
  .servicepage-section{
    height: 4300px;
  }
  .servicepage-item{
    height: 350px;
    margin: 20px 0 ;

  }
}
