.doctors-section {
  margin-top: -10px;
  width: auto;
  height: 1300px;
  background-color: #3898ec;
}

.doctors-content {
  max-width: 940;
  margin: 80px auto 0;
}
.doctors-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: 800px;
  gap: 40px;
}
.doctors-profile {
  margin: 10px 0;
  width: 350px;
  height: 420px;
  background-color: #ffffff;
}
.doctors-profile img {
  width: 350px;
  height: 250px;
}
.doctors-profile h1 {
  font-size: 38px;
  margin: 20px 0 10px;
  width: auto;
  height: auto;
  line-height: 44px;
  font-weight: 700;
  text-align: center;
}
.doctors-profile h3 {
  font-size: 20px;
  width: auto;
  height: auto;
  font-weight: 400;
  text-align: center;
  line-height: 40px;
}
.doctors-profile p {
  font-size: 16px;
  width: auto;
  height: auto;
  font-weight: 400;
  text-align: center;
  line-height: 40px;
}
.blue {
  color: #ffffff;
}
@media (max-width: 900px) {
  .doctors-section {
    height: 1150px;
  }
  .doctors-content {
    max-width: 728px;
    margin: 40px auto;
  }
  .doctors-grid {
    width: 700px;
    margin: 0 34px;
  }
  .doctors-profile {
    width: 300px;
    height: 370px;
  }
  .doctors-profile img {
    width: 300px;
    height: 200px;
  }
}
@media (max-width: 768px) {
  .doctors-section {
    height: 1150px;
  }
  .doctors-content {
    max-width: 728px;
    margin: 40px auto;
  }
  .doctors-grid {
    width: 700px;
    margin: 0 34px;
  }
  .doctors-profile {
    width: 300px;
    height: 370px;
  }
  .doctors-profile img {
    width: 300px;
    height: 200px;
  }
}
@media (max-width: 478px) {
  .doctors-section {
    height: 2000px;
  }
  .doctors-grid {
    margin: 0 auto;
    width: 280px;
    grid-template-columns: 1fr;
  }
  .doctors-content {
    max-width: 320px;
    width: 300px;
  }
  .doctors-profile {
    margin: 0 auto;
    width: 280px;
  }
  .doctors-profile img {
    width: 280px;
    height: 200px;
  }
}
