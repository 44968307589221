.hero-section {
  width: auto;
  height: 500px;
  background-color: #dbe3dd;
  /* margin-bottom: -50px; */
}
.hero-container {
  max-width: 940px;
  margin: 0 auto 40px;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
}
.hero-text h1 {
  width: 430px;
  height: 130px;
  font-size: 40px;
  line-height: 60px;
  font-weight: 700;
}
.hero-text p {
  width: 350px;
  height: 100px;
  font-size: 18px;
  font-weight: 300;
}
.hero-content {
  max-width: 940px;
  width: auto;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.hero-container img {
  width: 450px;
  height: 500px;
}
.hero-btn {
  width: 180px;
  height: 50px;
  margin-top: -130px;
  border: none;
  background-color: #3898ec;
  color: #ffffff;
  font-size: 16px;
  padding: 15px 15px 0;
  border-radius: 10px;
  text-decoration: none;
}
.hero-btn:hover {
  background-color: #ffffff;
  color: black;
  text-decoration: none;
}
.center-grid {
  width: auto;
  height: auto;
  max-width: 940px;
  margin: 0 auto;
}
.center-content {
  width: 220px;
  height: 80px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 10px;
}
.center-content img {
  width: 50px;
  height: 60px;
  padding-bottom: 10px;
}
.center-content p {
  font-size: 25px;
  width: 150px;
  height: 50px;
  text-align: center;
}
.grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  height: auto;
  width: 700px;
  margin: 0 auto;
  padding: 0 10px;
  gap: 30px;
}

@media (max-width: 900px) {
  .hero-section {
    height: 480px;
    padding: 40px 0;
  }
  .hero-container {
    max-width: 728px;
    width: 0 auto 40px;
  }

  .hero-text h1 {
    width: 400px;
    height: 100px;
    font-size: 35px;
    line-height: 40px;
  }
  .hero-text p {
    width: 350px;
    height: 80px;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
  }
  .hero-content img {
    width: 300px;
    height: 430px;
  }
  .hero-btn {
    font-size: 17px;
    margin-top: -80px;
  }
  .hero-content {
    margin-top: 10px;
    align-items: center;
    justify-content: left;
  }
  .center-grid {
    max-width: 728px;
  }
  .grid {
    width: 600px;
    padding: 10px 0;
    gap: 16px;
  }
  .center-content{
    width: 180px;
  }
  .center-content p{
    width: 130px;
    font-size: 20px;
    line-height: 20px;
  }
  .center-content img{
    width: 35px;
    height: 45px;
  }
}
@media (max-width: 768px) {
  .hero-section {
    height: 450px;
    padding: 40px 0;
  }
  .hero-container {
    max-width: 728px;
    width: 0 auto 40px;
  }

  .hero-text h1 {
    width: 350px;
    height: 100px;
    font-size: 30px;
    line-height: 35px;
  }
  .hero-text p {
    width: 350px;
    height: 80px;
    font-size: 17px;
    font-weight: 300;
    line-height: 25px;
  }
  .hero-content img {
    width: 300px;
    height: 400px;
    
  }
  .hero-btn {
    margin-top: -80px;
  }
  .hero-content {
    align-items: center;
    justify-content: left;
  }
  .center-grid {
    max-width: 728px;
  }
  .grid {
    width: 600px;
    padding: 10px 0;
    gap: 16px;
  }
  .center-content{
    width: 180px;
  }
  .center-content p{
    width: 130px;
    font-size: 20px;
    line-height: 20px;
  }
  .center-content img{
    width: 35px;
    height: 45px;
  }
}
@media (max-width: 478px) {
  .hero-section{
    /* margin-bottom: -100px; */
    height: 700px;
  }
  .hero-content{
    display: block;
    margin: 0 auto;
  }
  .hero-text{
    width: 270px;
    height: 200px;
    margin: 0 auto;
  }
  .hero-text h1{
    font-size: 20px;
    margin: 0 auto;
    text-align: center;
    width: 250px;
    height: 80px;
    line-height: 35px;
  }
  .hero-text p{
    text-align: center;
    width: 250px;
    height: 120px;
    margin: 0 auto;
    font-size: 16px;
    line-height: 25px;
  }
  .hero-content img{
    margin: 0 20px;
    width: 300px;
    height: 350px;
  }
  .hero-btn{
    font-size: 12px;
    width: 150px;
    margin: 25px auto 0;
  }
  .grid{
    padding: 0 10px;
    display: flex;
    flex-direction: column;
    width: 300px;
    gap: 10px;
    align-items: center;
    justify-content: center;
    margin: 20px auto;
  }
}