.about-section {
  margin-top: 10px;
  width: auto;
  height: auto;
  background-color: #3898ec;
}
.about-container {
  margin: 0 auto;
  max-width: 940px;
  position: relative;
}
.about-content {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: left;
  width: auto;
  height: auto;
  gap: 50px;
  margin-top: 70px;
}

.about-content img {
  width: 620px;
  height: 520px;
  /* margin-top: 50px;  */
}
.about-text {
  width: 370px;
  height: 400px;
  padding: 40px 10px 20px;
  margin-top: 50px;
}
.about-text h1 {
  width: 350px;
  height: 100px;
  margin: 20px 0 10px;
  font-size: 35px;
  line-height: 45px;
  color: #ffffff;
}
.about-text p {
  width: 340;
  height: auto;
  font-size: 18px;
  color: #ffffff;
  line-height: 30px;
}
.about-btn {
  padding: 5px 15px;
  font-size: 25px;
  line-height: 40px;
  border-radius: 7px;
  width: 180px;
  height: 50px;
  background-color: #ffffff;
  position: absolute;
  top: 450px;
  right: 50px;
  text-align: center;
  text-decoration: none;
}

.a-btn:hover {
  background-color: #2372a3;
  text-decoration: none;
  color: white;
}
.about-btn:hover{
  background-color: #2372a3;
  color: #ffffff;
}
@media (max-width: 900px) {
  .about-container {
    max-width: 790px;
  }
  .about-content img {
    width: 350px;
    height: 400px;
    margin: 50px 0;
  }
  .about-text h1 {
    width: 300px;
    height: 70px;
    font-size: 20px;
    line-height: 30px;
  }
  .about-text p {
    width: 240px;
    font-size: 16px;
    line-height: 20px;
  }
  .about-btn {
    height: 50px;
    padding: 5px 15px;
    top: 390px;
    right: 160px;
  }
  .about-section {
    /* margin-top: -120px; */
    height: 500px;
  }
} 


@media (max-width: 768px) {
  .about-container {
    max-width: 728px;
  }
  .about-content img {
    width: 350px;
    height: 400px;
    margin: 50px 0;
  }
  .about-text h1 {
    width: 300px;
    height: 70px;
    font-size: 20px;
    line-height: 30px;
  }
  .about-text p {
    width: 240px;
    font-size: 16px;
    line-height: 18px;
  }
  .about-btn {
    height: 50px;
    padding: 5px 15px;
    top: 370px;
    right: 100px;
  }
  /* .about-section {
    margin-top: -120px;
  } */
}

@media (max-width: 478px) {
  .about-section {
    /* margin-top: -200px; */
    height: 950px;
  }

  .about-content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: start;
  }
  .about-content img {
    width: 300px;
    height: 450px;
    margin: 40px auto 0;
    position: absolute;
    top: 313px;
    right: 20px;
    left: 10px;
  }
  .about-text {
    width: 300px;
    margin: 20px auto 0;
    padding: 40px 10px 20px;
  }
  .about-text h1 {
    width: 250px;
    height: 100px;
    font-size: 25px;
    line-height: 35px;
    margin: 0 auto 10px;
    text-align: center;
  }
  .about-text p {
    text-align: center;
    font-size: 16px;
    line-height: 20px;
    width: 300px;
    height: 180px;
  }
  .about-btn {
    margin: 0 auto;
    width: 180px;
    height: 50px;
    position: absolute;
    top: 856px;
    right: 20px;
    left: 5px;
  }

}